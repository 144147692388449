import type { CursorPagination, Pagination } from "@asap/shared/src/types/pagination";
import { useElementVisibility } from "@vueuse/core";
/**
 * Composable for implementing keyset-based pagination with infinite scroll.
 * ----
 * Keyset pagination is a pagination technique that allows you to paginate data
 * from a database using a cursor instead of an offset.
 *
 * A cursor is an object that contains a cursor_id and a cursor_created_at, the values will be
 * the proprieties of the last record of a page's list.
 *
 * For more about keyset pagination, you can read the following article:
 * https://medium.com/swlh/sql-pagination-you-are-probably-doing-it-wrong-d0f2719cc166
 *
 *
 * @example
 * ```html
 * <div ref="listContainer">
 *   <div v-for="record in records" :key="record.id">
 *     {{ record.name }}
 *   </div>
 *   <div ref="listBottom" style="height: 1px"></div>
 * </div>
 * ```
 * ```ts
 * const listContainer = ref<HTMLElement | null>(null);
 * const listBottom = ref<HTMLElement | null>(null);
 *
 * const queryFn = async (cursor?: CursorPagination) => {
 *   return await api.getItems({ ...filters, cursor });
 * };
 *
 * const {
 *   records,        // Paginated records
 *   loading,        // Loading state
 *   isFirstLoad,    // First load state (useful for skeletons)
 *   refresh,        // Function to reset and reload data
 *   scrollToTop,    // Function to scroll to top of list
 *   showScrollToTop // Boolean to show/hide scroll-to-top button
 * } = useKeysetPagination(listContainer, listBottom, queryFn);
 * ```
 *
 * @param listContainer - Ref to the container element of the list
 * @param listBottom - Ref to the bottom sentinel element for infinite scroll
 * @param queryFn - Function that fetches paginated data using cursor
 */
export const useKeysetPagination = <
  T extends { id: string; created_at: string; updated_at?: string | null; name?: string },
>(
  listContainer: Ref<HTMLElement | null>,
  listBottom: Ref<HTMLElement | null>,
  queryFn: (pagination?: Pagination) => Promise<T[] | undefined>
) => {
  const cursor = ref<CursorPagination>();
  const offset = ref(0);

  const currentAutoCompleteController = ref<AbortController | null>(null);

  const isFirstLoad = ref(false);
  const loading = ref(false);
  const records = ref<T[]>([]);

  const { addToastError } = useToast();

  // Updates the cursor with the last record's id and created_at
  const updateCursor = (records: T[]) => {
    if (records.length) {
      cursor.value = {
        cursor_created_at: records[records.length - 1].created_at,
        cursor_id: records[records.length - 1].id,
        cursor_updated_at: records[records.length - 1].updated_at ?? undefined,
        cursor_name: records[records.length - 1].name ?? undefined,
      };
    }
  };

  const isListBottomVisible = useElementVisibility(listBottom);

  // Wraps the query function to handle cursor and records' list update
  const paginationQueryWrapper = async (
    callback: (pagination?: Pagination, signal?: AbortSignal) => Promise<T[] | undefined>
  ) => {
    loading.value = true;
    try {
      // Abort previous request if it exists
      if (currentAutoCompleteController.value) currentAutoCompleteController.value.abort("Aborted by user");

      currentAutoCompleteController.value = new AbortController();

      const response = await callback(
        { cursor: cursor.value, offset: offset.value },
        currentAutoCompleteController.value.signal
      );
      if (response?.length) {
        records.value = [...records.value, ...response] as T[];
        updateCursor(response);
        offset.value += response.length;
      }
    } finally {
      loading.value = false;
    }
  };

  // Handle infinite scroll
  watch(isListBottomVisible, async (isVisible) => {
    try {
      if (isVisible && !loading.value) {
        await paginationQueryWrapper(queryFn);
      }
    } catch (error) {
      addToastError({ title: "Une erreur est survenue lors de la récupération des données" }, error);
    }
  });

  // Reset and refresh data
  const refresh = async () => {
    try {
      if (isFirstLoad.value) return;
      cursor.value = undefined;
      offset.value = 0;
      records.value = [];
      isFirstLoad.value = true;
      await paginationQueryWrapper(queryFn);
    } catch (error) {
      addToastError({ title: "Une erreur est survenue lors de la récupération des données" }, error);
    } finally {
      isFirstLoad.value = false;
    }
  };

  onMounted(async () => {
    try {
      isFirstLoad.value = true;
      await paginationQueryWrapper(queryFn);
    } catch (error) {
      addToastError({ title: "Une erreur est survenue lors de la récupération des données" }, error);
    } finally {
      isFirstLoad.value = false;
    }
  });

  // Scroll to top functionality
  const scrollToTop = () => {
    listContainer.value?.scrollIntoView({ behavior: "smooth" });
  };
  const showScrollToTop = computed(() => cursor.value?.cursor_id);

  return {
    loading,
    isFirstLoad,
    records,
    refresh,
    scrollToTop,
    showScrollToTop,
    cursor,
    offset,
  };
};
